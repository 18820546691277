import config from '../config';
import {
  DYNAMIC_YIELD_API_KEY,
  DYNAMIC_YIELD_API_KEY_AHNU,
  DYNAMIC_YIELD_API_KEY_HOKA,
  DYNAMIC_YIELD_API_KEY_TEVA,
  DYNAMIC_YIELD_API_KEY_UGG,
  DYNAMIC_YIELD_API_KEY_UGGCOLLAB
} from '../env/server';

const dynamicYield = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Enable Dynamic Yield at a general level. */
      isDYEnabled: true,
      /** Enable Dynamic Yield client-side scripts. */
      isDYClientEnabled: true,
      dyID: DYNAMIC_YIELD_API_KEY ?? '',
      siteID: '8782152',
      baseURL: 'https://dy-api.com/v2',
      /** The DY Preview Parameter - when included in the URL, the DYservice will attempt to preview
       * the specified experience. {@link https://dy.dev/docs/preview-api-web#enabling-preview-mode DY Docs}.
       */
      previewParam: 'dyApiPreview'
    }
  },
  'AHNU-US': {
    default: {
      dyID: DYNAMIC_YIELD_API_KEY_AHNU ?? '',
      siteID: { default: '8787066', prod: '8787067' }
    }
  },
  'TEVA-US': {
    default: {
      dyID: DYNAMIC_YIELD_API_KEY_TEVA ?? '',
      siteID: { default: '8774673', prod: '8774674' }
    }
  },
  'UGG-COLLAB-US': {
    default: {
      dyID: DYNAMIC_YIELD_API_KEY_UGGCOLLAB ?? '',
      // TODO: Update the siteID for Teva - this is the same as Ahnu for now.
      siteID: { default: '8787066', prod: '8787067' }
    }
  },
  'UGG-US': {
    default: {
      dyID: DYNAMIC_YIELD_API_KEY_UGG ?? '',
      // TODO: Update the siteID for Teva - this is the same as Ahnu for now.
      siteID: { default: '8787066', prod: '8787067' }
    }
  },
  'HOKA-US': {
    default: {
      dyID: DYNAMIC_YIELD_API_KEY_HOKA ?? '',
      // TODO: Update the siteID for Teva - this is the same as Ahnu for now.
      siteID: { default: '8787066', prod: '8787067' }
    }
  },
  'KOOLABURRA-US': {
    default: {
      dyID: (DYNAMIC_YIELD_API_KEY_TEVA as string) ?? '',
      // TODO: Update the siteID for Teva - this is the same as Ahnu for now.
      siteID: { default: '8787066', prod: '8787067' }
    }
  }
});

export default dynamicYield;
