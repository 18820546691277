import config from '../config';
import {
  SCAPI_SHORT_CODE,
  SCAPI_ORG_ID,
  SCAPI_PRIVATE_CLIENT_ID,
  SCAPI_PRIVATE_CLIENT_SECRET
} from '../env/server';

/** Configuration for Salesforce Commerce Cloud integrations. */
const sfcc = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Options for the B2C Commerce API, a.k.a. Salesforce Commerce API (SCAPI).
       * @see https://developer.salesforce.com/docs/commerce/commerce-api/overview
       */
      scapi: {
        /**
         * Base URL to use for SCAPI requests.
         * @see https://developer.salesforce.com/docs/commerce/commerce-api/guide/base-url.html#base-url-pattern
         */
        baseURL: `https://${SCAPI_SHORT_CODE}.api.commercecloud.salesforce.com`,

        /**
         * Short string that identifies a B2C Commerce instance.
         * @see https://developer.salesforce.com/docs/commerce/commerce-api/guide/base-url.html#organization-id
         */
        organizationID: SCAPI_ORG_ID,

        /**
         * Options for the Shopper Login and API Access Service (SLAS), which
         * enables secure access to the Shopper APIs.
         *
         * @see https://developer.salesforce.com/docs/commerce/commerce-api/guide/slas.html
         */
        slas: {
          /**
           * The ID for the private client Next's BFF will use.
           * @see https://developer.salesforce.com/docs/commerce/commerce-api/guide/slas.html#slas-clients
           */
          privateClientID: SCAPI_PRIVATE_CLIENT_ID,

          /**
           * The secret for the private client Next's BFF will use.
           * @see https://developer.salesforce.com/docs/commerce/commerce-api/guide/slas.html#slas-clients
           */
          privateClientSecret: SCAPI_PRIVATE_CLIENT_SECRET
        }
      }
    }
  }
});

export default sfcc;
