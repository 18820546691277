/* eslint-disable filenames/match-exported -- Not worth the refactor. */
import affiliates from './affiliates';
import blog from './blog';
import cards from './cards';
import carriers from './carriers';
import cart from './cart';
import checkout from './checkout';
import checkoutTimeout from './checkoutTimeout';
import content from './content';
import cookies from './cookies';
import ecommerce from './ecommerce';
import environment from './environment';
import featureFlagging from './featureFlagging';
import footer from './footer';
import giftCard from './giftCard';
import header from './header';
import help from './help';
import inventory from './inventory';
import locale from './locale';
import media from './media';
import menu from './menu';
import metadata from './metadata';
import navigation from './conductor/navigation';
import order from './order';
import personalization from './personalization';
import product from './product';
import productList from './productList';
import recommendations from './recommendations';
import returnAddress from './returnAddress';
import returns from './returns';
import reviews from './reviews';
import routes from './routes';
import search from './search';
import avaTax from './services/avaTax';
import session from './session';
import shippingMethods from './shippingMethods';
import sitemap from './sitemap';
import socials from './socials';
import states from './states';
import tagManagement from './tagManagement';
import test from './test';
import tracking from './tracking';
import ui from './ui';
import user from './user';
import studentDiscount from './studentDiscount';

// Integrations.
import applePay from './services/applePay';
import aws from './services/aws';
import awsServices from './services/awsServices';
import builderio from './services/builderio';
import cloudinary from './services/cloudinary';
import cognito from './services/cognito';
import coremedia from './services/coremedia';
import coveo from './services/coveo';
import datadog from './services/datadog';
import doms from './services/doms';
import dynamicYield from './services/dynamicYield';
import easypost from './services/easypost';
import elasticPath from './services/elasticPath';
import forter from './services/forter';
import locally from './services/locally';
import magtek from './services/magtek';
import osano from './services/osano';
import paypal from './services/paypal';
import safetech from './services/safetech';
import sfcc from './services/sfcc';
import talonOne from './services/talonOne';
import otel from './services/otel';
import yotpo from './services/yotpo';
import ups from './services/ups';
import pitneybowes from './services/pitneybowes';

/** All known site configurations. */
const configs = {
  affiliates,
  applePay,
  avaTax,
  aws,
  awsServices,
  builderio,
  blog,
  cards,
  carriers,
  cart,
  checkout,
  checkoutTimeout,
  cloudinary,
  cognito,
  content,
  cookies,
  coremedia,
  coveo,
  datadog,
  doms,
  dynamicYield,
  easypost,
  ecommerce,
  environment,
  elasticPath,
  featureFlagging,
  footer,
  forter,
  giftCard,
  header,
  help,
  inventory,
  locale,
  locally,
  magtek,
  media,
  menu,
  metadata,
  navigation,
  order,
  osano,
  otel,
  paypal,
  personalization,
  pitneybowes,
  product,
  productList,
  recommendations,
  returnAddress,
  returns,
  reviews,
  routes,
  safetech,
  search,
  session,
  shippingMethods,
  sitemap,
  socials,
  states,
  tagManagement,
  talonOne,
  test,
  tracking,
  ui,
  user,
  studentDiscount,
  yotpo,
  ups,
  sfcc
};

export default configs;

/** Represents all config objects. */
export type Configs = typeof configs;

/** Represents a given config name for a known config object. */
export type ConfigName = keyof Configs;

export { csp } from './csp';
export { local, default as mocked } from './mocked';

export * from './types/ConfigSchema';
