import { HTTPMethod } from '@/type-utils/HTTPMethod';
import config from '../config';

const awsServices = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      product: {
        methods: {
          getModel: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          },
          getPlatform: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          },
          getModelColors: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          },
          getModelSizes: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          },
          getColor: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          },
          getSize: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          },
          getInventory: {
            endpoint: 'inventory',
            httpMethod: HTTPMethod.Get
          },
          getSizeChart: {
            endpoint: 'product',
            httpMethod: HTTPMethod.Get
          }
        }
      },

      session: {
        // All session methods hit the same endpoint but with different methods.
        methods: {
          setSession: {
            endpoint: 'session',
            httpMethod: HTTPMethod.Post
          },
          getSession: {
            endpoint: 'session',
            httpMethod: HTTPMethod.Get
          }
        }
      },

      cart: {
        methods: {
          getCart: {
            endpoint: 'carts/{cartID}',
            httpMethod: HTTPMethod.Get
          },
          getNewCart: {
            endpoint: 'carts',
            httpMethod: HTTPMethod.Post
          },
          mergeCarts: {
            endpoint: 'carts/{destinationCartID}/actions/merge',
            httpMethod: HTTPMethod.Post
          },
          deleteCart: {
            endpoint: 'carts/{cartID}',
            httpMethod: HTTPMethod.Delete
          },
          postItem: {
            endpoint: 'carts/{cartID}/items',
            httpMethod: HTTPMethod.Post
          },
          deleteItem: {
            endpoint: 'carts/{cartID}/items/{itemID}',
            httpMethod: HTTPMethod.Delete
          },
          patchItem: {
            endpoint: 'carts/{cartID}/items/{itemID}',
            httpMethod: HTTPMethod.Patch
          },
          postCoupon: {
            endpoint: 'carts/{cartID}/coupons',
            httpMethod: HTTPMethod.Post
          },
          deleteCoupon: {
            endpoint: 'carts/{cartID}/coupons/{couponID}',
            httpMethod: HTTPMethod.Delete
          },
          postGiftCard: {
            endpoint: 'carts/{cartID}/giftcards',
            httpMethod: HTTPMethod.Post
          },
          deleteGiftCard: {
            endpoint: 'carts/{cartID}/giftcards/{cardID}',
            httpMethod: HTTPMethod.Delete
          },
          postPromo: {
            endpoint: 'carts/{cartID}/promotions',
            httpMethod: HTTPMethod.Post
          },
          deletePromo: {
            endpoint: 'carts/{cartID}/promotions/{promoID}',
            httpMethod: HTTPMethod.Delete
          },
          putContext: {
            endpoint: 'carts/{cartID}/context/add/{contextID}',
            httpMethod: HTTPMethod.Put
          }
        }
      },

      pcache: {
        methods: {
          setPcache: {
            endpoint: 'set-pcache',
            httpMethod: HTTPMethod.Post
          }
        }
      },

      account: {
        methods: {
          // Account endpoints.
          getAccount: {
            endpoint: 'account',
            httpMethod: HTTPMethod.Get
          },
          putAccount: {
            endpoint: 'account',
            httpMethod: HTTPMethod.Put
          },
          postAccount: {
            endpoint: 'account',
            httpMethod: HTTPMethod.Post
          },
          deleteAccount: {
            endpoint: 'account',
            httpMethod: HTTPMethod.Delete
          },
          // Account Profile endpoints.
          getProfile: {
            endpoint: 'account/profile',
            httpMethod: HTTPMethod.Get
          },
          putProfile: {
            endpoint: 'account/profile',
            httpMethod: HTTPMethod.Put
          },
          // Account Wallet endpoints.
          getWallet: {
            endpoint: 'account/wallet',
            httpMethod: HTTPMethod.Get
          },
          getPaymentInstrument: {
            endpoint: 'account/wallet/{paymentInstrumentID}',
            httpMethod: HTTPMethod.Get
          },
          putPaymentInstrument: {
            endpoint: 'account/wallet/{paymentInstrumentID}',
            httpMethod: HTTPMethod.Put
          },
          deletePaymentInstrument: {
            endpoint: 'account/wallet/{paymentInstrumentID}',
            httpMethod: HTTPMethod.Delete
          },
          // Account Address-book endpoints.
          getAddressBook: {
            endpoint: 'account/address',
            httpMethod: HTTPMethod.Get
          },
          getAddress: {
            endpoint: 'account/address/{addressID}',
            httpMethod: HTTPMethod.Get
          },
          putAddress: {
            endpoint: 'account/address/{addressID}',
            httpMethod: HTTPMethod.Put
          },
          deleteAddress: {
            endpoint: 'account/address/{addressID}',
            httpMethod: HTTPMethod.Delete
          },
          // Password
          changePassword: {
            endpoint: 'account/password',
            httpMethod: HTTPMethod.Put
          },
          forgotPassword: {
            endpoint: 'account/forgot',
            httpMethod: HTTPMethod.Get
          },
          forgotPasswordReset: {
            endpoint: 'account/forgot',
            httpMethod: HTTPMethod.Post
          }
        }
      },

      transaction: {
        methods: {
          createOrder: {
            endpoint: 'order',
            httpMethod: HTTPMethod.Put
          },
          getOrderID: {
            endpoint: `order`,
            httpMethod: HTTPMethod.Post
          },
          lookUpOrder: {
            endpoint: 'order',
            httpMethod: HTTPMethod.Get
          }
        }
      },

      blog: {
        methods: {
          getAll: {
            endpoint: 'blog',
            httpMethod: HTTPMethod.Get
          },
          getOne: {
            endpoint: 'blog/{slug}',
            httpMethod: HTTPMethod.Get
          }
        }
      },

      category: {
        methods: {
          listCategories: {
            endpoint: 'categories',
            httpMethod: HTTPMethod.Get
          },
          getCategoryByID: {
            endpoint: 'categories/{categoryId}',
            httpMethod: HTTPMethod.Get
          },
          createCategory: {
            endpoint: 'categories',
            httpMethod: HTTPMethod.Post
          },
          updateCategory: {
            endpoint: 'categories/{categoryId}',
            httpMethod: HTTPMethod.Patch
          },
          deleteCategory: {
            endpoint: 'categories/{categoryId}',
            httpMethod: HTTPMethod.Delete
          }
        }
      },

      returns: {
        methods: {
          placeReturn: {
            endpoint: 'order/{orderId}/return',
            httpMethod: HTTPMethod.Post
          },
          placeRetailReturn: {
            endpoint: 'order/retail',
            httpMethod: HTTPMethod.Post
          }
        }
      },
      shippingMethod: {
        methods: {
          getShippingMethods: {
            endpoint: 'shipping-methods',
            httpMethod: HTTPMethod.Get
          },

          createShippingMethod: {
            endpoint: 'shipping-methods',
            httpMethod: HTTPMethod.Post
          },

          getShippingMethod: {
            endpoint: 'shipping-methods/{shippingMethodID}',
            httpMethod: HTTPMethod.Get
          },

          updateShippingMethod: {
            endpoint: 'shipping-methods/{shippingMethodID}',
            httpMethod: HTTPMethod.Put
          },

          deleteShippingMethod: {
            endpoint: 'shipping-methods/{shippingMethodID}',
            httpMethod: HTTPMethod.Delete
          }
        }
      }
    }
  }
});

export default awsServices;
