import config from './config';

const reviews = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Default options for retrieving reviews.
       * These might be customized so that the reviews by default are sorted
       * based on upvotes (which might be a more useful metric).
       */
      perPage: 20,
      sort: 'date',
      sortDirection: 'desc',

      /**
       * Questions are not actually paginated with the reviews service.
       * All of them are loaded into the model at the same time,
       * however they are paginated artificially on the front-end.
       */
      questionsPerPage: 50,
      /** Customer reviews settings. */
      customerReviews: {
        /**
         * Controls whether customer reviews should
         * be displayed on the PDP.
         */
        show: true,
        /**
         * Controls whether the "Write a Review" button should be displayed.
         */
        isWriteAReviewEnabled: true,
        /**
         * Controls whether the "Write a Review" button should open in a modal.
         */
        isWriteAReviewInModal: true,
        /**
         * Controls whether the "Helpful" buttons (up and down) should be displayed.
         */
        canVote: true,
        /** The customer reviews rating star size. */
        reviewStarSize: 18,
        /** The customer reviews average rating star size. */
        avgStarSize: 24
      }
    }
  },
  'AHNU-US': {
    default: {
      customerReviews: {
        show: true,
        isWriteAReviewEnabled: true,
        canVote: true
      }
    }
  }
});

export default reviews;
