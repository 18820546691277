import config from './config';

const cart = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Whether a fresh cart should be loaded on every page.
       * If false it will only fresh load on cart and checkout.
       */
      isReloadedOnEveryPage: true,

      /**
       * Whether the cart's initial revalidation (loading of totals and promotions)
       * should be lazy. If it is false, some pages will wait for revalidation to
       * finish before rendering, which can sometimes cause long loading times,
       * such as if the the user starts on the cart without having one at hand.
       */
      isInitialRevalidationLazy: false,

      /**
       * The maximum quantity (inclusive) of a single line item that can be added to the cart.
       * This value also corresponds to the number of options that appear in the
       * quantity dropdown of a line item on the cart page.
       *
       * This value **must** be a positive integer less than or equal to `maxQuantityPerCart`.
       */
      maxQuantityPerLine: 10,

      /**
       * The maximum total quantity (inclusive) of items that can be added to the cart and
       * purchased in a single order.
       *
       * This does **not** explicitly limit the number of _unique_ line items that
       * can be added to the cart. However, the number of unique line items obviously
       * cannot exceed the max quantity of the cart.
       *
       * This value **must** be a positive integer greater than or equal to `maxQuantityPerLine`.
       */
      maxQuantityPerCart: 10,

      /**
       * Whether a guest cart should be merged with the authenticated user's cart, if they have one,
       * upon login. If `false`, the user will lose their guest cart, and instead be assigned the cart
       * tied to their account, or a fresh cart if they don't have one.
       */
      isMergeCartEnabled: {
        default: true,
        prod: false
      },
      /**
       * Whether the product's availability message should be displayed above or near the Add to Cart button.
       */
      addToCartButtonAvailabilityMsg: false
    }
  },
  'AHNU-US': {
    default: {
      addToCartButtonAvailabilityMsg: true
    }
  }
});

export default cart;
