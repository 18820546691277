import config from './config';

const header = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * The default banner placement is below the header. It can optionally be
       * placed above the header by setting this to true.
       */
      isBannerAtTop: false,
      /**
       * Default behavior is to have header banners collapse when scrolled
       * out of view.
       */
      allowBannerScrollCollapse: true,
      /**
       * Should the desktop header be simplified to be like the mobile header?
       */
      isSimplifiedDesktopLayout: false,
      /**
       * Should the account text be shown next to the user icon in the header?
       */
      showAccountHeaderText: false
    }
  },
  'AHNU-US': {
    default: {
      isBannerAtTop: true,
      allowBannerScrollCollapse: false,
      isSimplifiedDesktopLayout: true
    }
  },
  'UGG-COLLAB-US': {
    default: {
      isBannerAtTop: true,
      allowBannerScrollCollapse: false
    }
  },
  'UGG-US': {
    default: {
      isBannerAtTop: true,
      allowBannerScrollCollapse: false
    }
  },
  'HOKA-US': {
    default: {
      isBannerAtTop: true,
      allowBannerScrollCollapse: false
    }
  },
  'KOOLABURRA-US': {
    default: {
      isBannerAtTop: true,
      allowBannerScrollCollapse: false
    }
  },
  'TEVA-US': {
    default: {
      showAccountHeaderText: true
    }
  }
});

export default header;
